var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" Consulting Services "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addCourseFormvalidate"},[_c('b-form',{staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{model:{value:(_vm.addCourseForm.description),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "description", $$v)},expression:"addCourseForm.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2",staticStyle:{"margin":"0px"}},[_c('h4',{staticClass:"mb-1"},[_vm._v("header image")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('div',{staticClass:"d-inline-block"},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewE2",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":("https://api.lmitac.com/" + (_vm.addCourseForm.header_img)),"height":"110","width":"170"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"rounded ml-1 mt-2 text-center",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.refPreviewE2.src = _vm.Tabimage
              _vm.addCourseForm.header_img=''}}},[_vm._v(" Remove Image ")])],1),_c('b-media-body',[_c('b-card-text',{staticClass:"mt-1"}),_c('div',{staticClass:"d-inline-block mt-1"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"header image","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"header Image "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputE2",attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"Choose file"},on:{"input":_vm.inputImageRendererTab},model:{value:(_vm.addCourseForm.header_img),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "header_img", $$v)},expression:"addCourseForm.header_img"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Image Caption","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":"alt-image"},model:{value:(_vm.addCourseForm.alt_header_img),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "alt_header_img", $$v)},expression:"addCourseForm.alt_header_img"}})],1)],1)],1)],1)],1)]),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Keywords","label-for":"blog-content"}},[_c('b-form-tags',{model:{value:(_vm.addCourseForm.keywords),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "keywords", $$v)},expression:"addCourseForm.keywords"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":" Meta Title","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Meta Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title"},model:{value:(_vm.addCourseForm.meta_title),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "meta_title", $$v)},expression:"addCourseForm.meta_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Meta Description","label-for":"blog-edit-slug"}},[_c('validation-provider',{attrs:{"name":"Meta Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-slug"},model:{value:(_vm.addCourseForm.meta_description),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "meta_description", $$v)},expression:"addCourseForm.meta_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }