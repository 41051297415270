<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Consulting Services

        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="blog-content" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="">
                <quill-editor v-model="addCourseForm.description" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="">
            <div class="border rounded p-2" style="margin: 0px">
              <h4 class="mb-1">header image</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              > <div class="d-inline-block">
              
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="`https://api.lmitac.com/${addCourseForm.header_img}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0" />
                </b-media-aside>
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="rounded ml-1 mt-2 text-center"
                @click="refPreviewE2.src = Tabimage
                addCourseForm.header_img=''">
                Remove Image
              </b-button>
            </div>
            <b-media-body>
              <b-card-text class="mt-1"> </b-card-text>
              <div class="d-inline-block mt-1">
                    <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2">
                      <validation-provider
                        #default="{ errors }"
                        name="header Image "
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="addCourseForm.header_img"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Image Caption"
                      label-for="blog-edit-title"
                      class="mb-2">
                      <b-form-input
                        id="alt-image"
                        v-model="addCourseForm.alt_header_img" />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group
              label="Title"
              label-for="blog-edit-title"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required">
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCourseForm.title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <!-- <b-col md="4">
            <b-form-group
              label="Description"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
              <b-form-input
                id="blog-edit-slug"
                v-model="addCourseForm.description"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col cols="4">
            <b-form-group
              label="Keywords"
              label-for="blog-content"
              class="mb-2">
              <b-form-tags v-model="addCourseForm.keywords" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label=" Meta Title"
              label-for="blog-edit-title"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Meta Title"
                rules="required">
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCourseForm.meta_title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Meta Description"
              label-for="blog-edit-slug"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Meta Description"
                rules="required">
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCourseForm.meta_description" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col cols="4">
            <b-form-group
              label=" Meta Keywords"
              label-for="blog-content"
              class="mb-2">
              <b-form-tags v-model="addCourseForm.meta_keywords" />
            </b-form-group>
          </b-col> -->

          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save">
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTags,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import Vue from "vue";
export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const refPreviewE2 = ref(null);
    const Tabimage = ref("media/svg/files/blank-image.svg");
    const categoriesList = ref([]);
    store.dispatch("categories/AllCategory").then((response) => {
   
      categoriesList.value = response.data.data;
    });
  
    const form=ref({ description: "",
      title: "",
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      keywords: "",
      header_img: "",
      alt_header_img: "",
      video_link: "",
      category_id: "",

    })
    const addCourseForm = reactive(form);
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
    const addCourseFormvalidate = ref();

    const save = () => {
    
      const formData = new FormData();

      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          formData.append("title", addCourseForm.value.title);
          formData.append("description", addCourseForm.value.description);
          formData.append("meta_title", addCourseForm.value.meta_title);
         
          formData.append("meta_description", addCourseForm.value.meta_description);
          formData.append("header_img", addCourseForm.value.header_img);
      formData.append("alt_header_img", addCourseForm.value.alt_header_img);
          formData.append(" meta_keywords", addCourseForm.value.meta_keywords);
         
          formData.append("keywords", addCourseForm.value.keywords);
        
        
          store
            .dispatch("setting/addConslutingService", formData)
            .then((response) => {
              Vue.swal({
                title: " Updated ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });

        
        }
      });
    };
    store.dispatch("setting/getConslutingService").then((response) => {

  form.value=response.data
  form.value.keywords=  response.data.keywords.split(',')
    })
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      categoriesList,
      form,
      save,
      required,
      email,
    };
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BFormTags,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
